<template>
    <v-container  class="px-10 py-5 mt-15">
        <v-row class="mb-15">
            <v-col  col="12" lg="4" md="4" class="py-5 mx-auto">
                <v-card dark color="transparent" class="elevation-0 text-align:center">
                    <h1>Coming soon</h1>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
      name: 'coming-main',
  }
  </script>
  
  <style>
  .floatchip{
      padding: 6px 8px;
      color: #010101;
      background: #ffffff;
      position: absolute;
      top: 10px;
      font-weight: bold;
      left: 10px;
      font-size: 12px;
      border-radius: 10px;
  }
  
  </style>