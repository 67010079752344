import { render, staticRenderFns } from "./crumbs.vue?vue&type=template&id=0a838c78&scoped=true&"
import script from "./crumbs.vue?vue&type=script&lang=js&"
export * from "./crumbs.vue?vue&type=script&lang=js&"
import style0 from "./crumbs.vue?vue&type=style&index=0&id=0a838c78&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a838c78",
  null
  
)

export default component.exports