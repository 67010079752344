<template>
    <v-container color="indigo darken-4" class="px-10 py-5 mt-15">
        <v-row class="mb-0">
            <v-col col="12" lg="5" md="5" class=" mt-5 py-5 mx-auto">
                <v-card dark flat class="GameCardColor form-card py-15 px-5">
                    <h2 class="SignupText mb-5 centered">Pay Now</h2>
                    <StripeElementPayment pk="pk_test_IJS6Ahoq9H3oZQPC27CmjaPj" ></StripeElementPayment>
                    <label>Card Number</label>
                    <div id="card-number"></div>
                    <label>Card Expiry</label>
                    <div id="card-expiry"></div>
                    <label>Card CVC</label>
                    <div id="card-cvc"></div>
                    <div id="card-error"></div>
                    <v-btn class="customGameButton" block type="submit"  @click="createToken">Chechout</v-btn>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col col="12" lg="5" md="5" class="mx-auto">
                <p class="text-center">
                    <router-link :to="{name:'signin'}" class="SidebarLink mx-auto">Already have account?</router-link>
                </p>
            </v-col>
        </v-row>
        <!-- Notifications -->
        <v-snackbar v-model="notificationBar" :timeout="10000" top center :color="notificationColor" multi-line class="mt-2 mr-8" :vertical="true"> {{ notificationText }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="notificationBar = false">Close</v-btn>
            </template>
        </v-snackbar>
    </v-container>
  </template>
  
  <script>
  import { StripeElementPayment } from '@vue-stripe/vue-stripe';
  export default {
    components: {
      StripeElementPayment,
  },
    name: 'check-out',
    data () {
      return {
        token: null,
        cardNumber: null,
       // cardExpiry: null,
        //cardCvc: null,
        // Other useful objects
        isLoading:false,
        //Notifications
        notificationBar:false,
        notificationColor:'black',
        notificationText:''
      };
    },
    computed: {
      stripeElements () {
        return this.$stripe.elements();
      },
    },
    mounted () {
      // Style Object documentation here: https://stripe.com/docs/js/appendix/style
    const appearance = {theme: 'night'};
    const secret = 'sk_test_Og1wmhTgbnYKOMpdlj9yaARz';
      this.cardNumber = this.$stripeElements({secret, appearance});
        this.cardNumber.mount('#card-number');
    },
    beforeDestroy () {
      this.cardNumber.destroy();
    },
    methods: {
      async createToken () {
        const { token, error } = await this.$stripe.createToken(this.cardNumber);
        if (error) {
          // handle error here
          document.getElementById('card-error').innerHTML = error.message;
          return;
        }
        console.log(token);
      },
    }
  };
  </script>
  
  <style scoped>
  #custom-button {
    height: 30px;
    outline: 1px solid grey;
    background-color: green;
    padding: 5px;
    color: white;
  }
  
  #card-error {
    color: red;
  }
  .checkout {
  border: 1px solid black;
  padding: 3px;
}
  </style>